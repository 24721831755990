import React from "react";
import { graphql } from "react-apollo";
import { DefaultLayout } from "./containers";
import meSocket from "./queries/meSocket";
import { WebSocketProvider } from "./WebSocketContext";
import { WebSocketHookWrapper } from "./WebSocketHookWrapper";
import { clearStore } from "./App";

function WebSocketWrapper(props) {

    const { data, history } = props;

    if (data.loading) return <div />;

    // No user is logged in
    if (data.error) return <DefaultLayout history={history} />;

    // Check if user exists in database
    if (!data.me && data.networkStatus === 7) {
        const removeList = ['token', 'role', 'organizationId', 'organization'];
        removeList.forEach(item => localStorage.removeItem(item));
        clearStore();
        window.location.href = '/login';
        return null;
    }

    return(
        <WebSocketProvider id={data.me.id} orgId={data.me.organization.id} orgPhones={data.me.organization.tollFrees.map(item => item.phone)} >
            <WebSocketHookWrapper>
                <DefaultLayout history={history} />
            </WebSocketHookWrapper>
        </WebSocketProvider>
    )
}

export default graphql(meSocket)(WebSocketWrapper);