import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import Icon from "@material-ui/core/Icon";
import { clearStore } from "../../App";
import { BsQuestionCircleFill } from "react-icons/bs";

const propTypes = {
  accnt: PropTypes.bool,
  orgs: PropTypes.bool,
  lists: PropTypes.bool,
  help: PropTypes.bool,
};
const defaultProps = {
  accnt: false,
  orgs: false,
  lists: false,
  help: false,
};

class DefaultHeaderDropdown extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
    };
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  logout() {
    const removeList = ["token", "role", "organizationId", "organization"];
    removeList.forEach((item) => localStorage.removeItem(item));
    clearStore();
    this.props.history.push("/login");
  }

  dropAccnt() {
    return (
      <Dropdown nav isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle nav>
          <i className="fa fa-user" style={{ fontSize: "16.4px" }} />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem header tag="div" className="text-center">
            <strong>Account</strong>
          </DropdownItem>
          <DropdownItem onClick={() => this.logout()}>
            <i className="fa fa-lock" /> Logout
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }

  dropHelp() {
    return (
      <Dropdown nav isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle nav>
          <BsQuestionCircleFill /> Help
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem header tag="div" className="text-center">
            <strong>Resource Center</strong>
          </DropdownItem>

          <DropdownItem
            onClick={() => {
              window.location.href = "https://leadwireapp.com/learn";
            }}
          >
            <BsQuestionCircleFill style={{ color: "grey" }} />
            <span style={{ marginLeft: "0.85rem" }}>Learning Portal</span>
          </DropdownItem>

          <DropdownItem
            onClick={() => {
              window.location.href =
                "https://leadwireapp.atlassian.net/servicedesk/customer/portals";
            }}
          >
            <span style={{ marginLeft: "0.4rem" }}>
              <i className="fa fa-headphones" />
              <span>Customer Support Portal</span>
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }

  dropList() {
    const isViewerRole = localStorage.getItem("role") === "VIEWER";
    return (
      <Dropdown
        className="d-md-down-none"
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
      >
        <DropdownToggle
          className="d-flex align-self-end"
          style={{ marginBottom: 0, padding: "0.575rem 0.75rem" }}
        >
          Manage Audience <Icon className="expand-icon">expand_more</Icon>
        </DropdownToggle>
        <DropdownMenu right className="dropdown-menu-lg">
          <DropdownItem
            className="text-center"
            onClick={() => this.props.onEdit()}
            disabled={isViewerRole}
          >
            <strong>Edit</strong>
          </DropdownItem>
          <DropdownItem
            className="text-center"
            onClick={() => this.props.onNewClient()}
            disabled={isViewerRole}
          >
            <strong>New Contact</strong>
          </DropdownItem>
          <DropdownItem
            className="text-center"
            onClick={() => this.props.onImport()}
            disabled={isViewerRole}
          >
            <strong>Import Contacts</strong>
          </DropdownItem>
          {/* <DropdownItem className="text-center" onClick={() => this.props.onEmbedForm()}><strong>Embed to Website</strong></DropdownItem> */}
          <DropdownItem
            className="text-center"
            onClick={() => this.props.onDelete()}
            disabled={isViewerRole}
          >
            <strong>Delete</strong>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }

  dropOrg() {
    return (
      <Dropdown
        className="d-md-down-none"
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
      >
        <DropdownToggle className="d-flex align-self-end">
          <Icon>settings</Icon>
        </DropdownToggle>
        <DropdownMenu right className="dropdown-menu-lg">
          <DropdownItem
            className="text-center"
            onClick={() => this.props.onEdit()}
          >
            <strong>Edit Organization</strong>
          </DropdownItem>
          <DropdownItem
            className="text-center"
            onClick={() => this.props.onSwitch()}
          >
            <strong>Switch to Organization</strong>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }

  dropAudiences() {
    return (
      <Dropdown>
        <DropdownToggle className="d-flex align-self-end">
          <Icon>settings</Icon>
        </DropdownToggle>
        <DropdownMenu right className="dropdown-menu-lg">
          <DropdownItem className="text-center" onClick={() => {}}>
            <strong>Custom Fields</strong>
          </DropdownItem>
          <DropdownItem className="text-center" onClick={() => {}}>
            <strong>Add Opt Out Contacts</strong>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }

  render() {
    const { accnt, orgs, lists, help, audiences } = this.props;
    return accnt
      ? this.dropAccnt()
      : orgs
        ? this.dropOrg()
        : lists
          ? this.dropList()
          : help
            ? this.dropHelp()
            : audiences
              ? this.dropAudiences()
              : null;
  }
}

DefaultHeaderDropdown.propTypes = propTypes;
DefaultHeaderDropdown.defaultProps = defaultProps;

export default DefaultHeaderDropdown;
