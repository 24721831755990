import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import React, { useCallback, useEffect, useState } from "react";

import { Button } from "reactstrap";
import { CircularProgress } from "@material-ui/core";
import { FaBan } from "react-icons/fa";
import Image from "../../../components/Image/Image";
import LWLogo from "../../../assets/img/brand/logo_tagline.png";
import Select from "react-select";
import axios from "axios";
import classes from "./DataEntry.module.css";
import { getIndustries } from "../../../pagination/fetchIndustry";
import { getOrganizationDataEntry } from "../../../pagination/fetchOrganizations";
import { getUser } from "../../../pagination/fetchUser";
import { withRouter } from "react-router-dom";

const DataEntry = (props) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [isDisabled, setIsDisabled] = useState(true);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [initialSelectedGoal, setInitialSelectedGoal] = useState(null);
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [industryOptions, setIndustryOptions] = useState(null);
  const [customGoal, setCustomGoal] = useState("");
  const [welcomeTitle, setWelcomeTitle] = useState("Welcome back 👋");
  const [isLoading, setIsLoading] = useState(true);
  const [isDelayed, setIsDelayed] = useState(false);
  const [website, setWebsite] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [orgLanguage, setOrgLanguage] = useState("en");

  const url = process.env.REACT_APP_PRISMA_URL;

  const goalOptions = [
    { value: "brandAwareness", label: "Brand Awareness" },
    { value: "onlineSales", label: "Online Sales" },
    { value: "websiteVisits", label: "Website Visits" },
    { value: "physicalStoreVisits", label: "Physical Store Visits" },
    { value: "other", label: "Other" },
  ];

  const optionStyle = {
    control: (provided) => ({
      ...provided,
      borderColor: "hsl(0,0%,80%)",
      boxShadow: null,
      cursor: "pointer",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: !state.isDisabled
        ? state.isSelected
          ? "var(--lw-blue-color)"
          : state.isFocused
          ? "var(--lw-blue-transparent-color)"
          : "white"
        : "#f5f5f5",
      color: state.isDisabled ? "#5d5d5d" : state.isSelected ? "white" : "",
      cursor: "pointer",
    }),
  };

  useEffect(() => {
    // Handle delayed loading indicator
    setTimeout(() => {
      setIsDelayed(true);
    }, 1000);

    const fetchIndustries = async () => {
      const industries = await getIndustries();
      return industries.map((industry) => ({
        value: industry.id,
        label: industry.name,
      }));
    };

    const fetchUser = async () => {
      const userResponse = await getUser();
      return userResponse ? userResponse.fname : null;
    };

    const fetchOrgInfo = async () => {
      const orgResponse = await getOrganizationDataEntry();
      return orgResponse;
    };

    const fetchData = async () => {
      try {
        const [industries, userName, orgInfo] = await Promise.all([
          fetchIndustries(),
          fetchUser(),
          fetchOrgInfo(),
        ]);

        setIndustryOptions(industries);
        setWebsite(orgInfo.site || "");

        if (orgInfo.language) {
          if (orgInfo.language === "SPANISH") {
            setOrgLanguage("es");
          } else if (orgInfo.language === "ENGLISH") {
            setOrgLanguage("en");
          }
        }

        const industry = orgInfo.industry;

        if (industry) {
          const convertCurrentIndustry = () => {
            return {
              value: industry.id,
              label: industry.name,
            };
          };

          setSelectedIndustry(convertCurrentIndustry());
          setIsDisabled(false);
        }

        const orgGoal = orgInfo.organizationGoal;
        if (orgGoal) {
          const findSelectedGoal = goalOptions.find(
            (option) => option.label === orgGoal
          );
          const otherOption = { value: "other", label: "Other" };
          const currentGoal = findSelectedGoal || otherOption;
          const currentCustomGoal = !findSelectedGoal && orgGoal;
          setSelectedGoal(currentGoal);
          setInitialSelectedGoal(currentGoal);
          setCustomGoal(currentCustomGoal || "");
        }

        if (userName) {
          setWelcomeTitle(`Welcome, ${userName} 👋`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false); // Set isLoading to false after both fetches are complete
      }
    };

    fetchData();
  }, []);

  const updateIsDisabled = useCallback(() => {
    let disabled = false;

    if (currentStep !== 2) {
      disabled = false;
    } else if (!selectedGoal) {
      disabled = true;
    } else if (selectedGoal.value === "other") {
      disabled = customGoal.trim() === "";
    } else if (
      selectedGoal.value === "websiteVisits" ||
      selectedGoal.value === "onlineSales"
    ) {
      // For these goals, website is required and must be valid
      disabled = !isValid;
    } else if (selectedGoal.value === "brandAwareness") {
      // Website is optional; submit button should not be disabled
      disabled = false;
    } else {
      // For all other goals
      disabled = false;
    }

    setIsDisabled(disabled);
  }, [currentStep, selectedGoal, customGoal, isValid, selectedIndustry]);

  useEffect(() => {
    updateIsDisabled();
  }, [updateIsDisabled]);

  const validateWebsite = (url) => {
    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );
    return urlPattern.test(url);
  };

  const handleGoalChange = (selectedOption) => {
    setSelectedGoal(selectedOption);

    // Reset isValid if goal doesn't require a website
    if (
      selectedOption.value !== "websiteVisits" &&
      selectedOption.value !== "onlineSales" &&
      selectedOption.value !== "brandAwareness"
    ) {
      setIsValid(true);
    }

    // For goals that require a website, re-validate the website URL
    if (
      selectedOption.value === "websiteVisits" ||
      selectedOption.value === "onlineSales" ||
      selectedOption.value === "brandAwareness"
    ) {
      const isWebsiteValid = validateWebsite(website);
      setIsValid(isWebsiteValid);
    }

    updateIsDisabled();
  };

  const handleWebsiteChange = (event) => {
    const newWebsite = event.target.value;
    setWebsite(newWebsite);
    const isWebsiteValid = validateWebsite(newWebsite);
    setIsValid(isWebsiteValid);

    updateIsDisabled();
  };

  const updateDisabledState = (goal, url, isUrlValid) => {
    const isGoalValid =
      goal &&
      (goal.value !== "other" ||
        (goal.value === "other" && customGoal.trim() !== ""));
    const requiresValidUrl =
      goal && (goal.value === "websiteVisits" || goal.value === "onlineSales");

    setIsDisabled(!isGoalValid || (requiresValidUrl && !isUrlValid));
  };

  useEffect(() => {
    // Run on mount to initialize the disabled state
    updateDisabledState(selectedGoal, website, isValid);
  }, [selectedGoal, website, isValid]);

  const handleSkip = async () => {
    // Mutation updateOrg query
    const updateOrgMutation = `mutation {
    updateOrganization(id: "${localStorage.getItem(
      "organizationId"
    )}", organizationGoal: "") {
      id
      organizationGoal
    }
  }`;

    if (!initialSelectedGoal) {
      try {
        await axios.post(
          url,
          {
            query: updateOrgMutation,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${process.env.REACT_APP_BEARER}`,
            },
          }
        );
      } catch (error) {
        console.error("Error updating organization skipping the form:", error);
      }
    }

    localStorage.removeItem("email");
    // Redirect to the previous route if available, otherwise default to "/dashboard"
    const previousRoute =
      (props.location.state && props.location.state.from) || "/dashboard";
    props.history.replace(previousRoute);
  };

  const handleIsOptionSelected = (selectedOption) => {
    setSelectedIndustry(selectedOption);
    setIsDisabled(false); // Enable the Next button after selecting an industry
  };

  const handleNext = async () => {
    if (currentStep === 1 && selectedIndustry) {
      setCurrentStep(currentStep + 1);
      setIsDisabled(true); // Reset for the next step
    }
  };

  const handleSubmit = async () => {
    if (currentStep === 2 && (selectedGoal || customGoal)) {
      // Include the website if provided and valid
      let isWebsite = "";
      if (
        (selectedGoal.value === "websiteVisits" ||
          selectedGoal.value === "onlineSales") &&
        isValid &&
        website
      ) {
        isWebsite = `, site: "${website}"`;
      } else if (
        selectedGoal.value === "brandAwareness" &&
        website &&
        isValid
      ) {
        isWebsite = `, site: "${website}"`;
      }

      const updateOrgMutation = `mutation {
      updateOrganization(id: "${localStorage.getItem(
        "organizationId"
      )}", organizationGoal: "${
        selectedGoal.value !== "other" ? selectedGoal.label : customGoal
      }", industryId: "${selectedIndustry.value}"
    ${isWebsite}) {
        id
      }
    }`;

      try {
        await axios.post(
          url,
          {
            query: updateOrgMutation,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${process.env.REACT_APP_BEARER}`,
            },
          }
        );
        localStorage.removeItem("email");
        // Redirect to the previous route if available, otherwise default to "/dashboard"
        const previousRoute =
          (props.location.state && props.location.state.from) || "/dashboard";
        props.history.replace(previousRoute);
      } catch (error) {
        console.error(
          "Error updating organization submitting the form:",
          error
        );
      }
    }
  };

  const handlePrevious = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
      setIsDisabled(false); // Re-enable the Next button if an industry was previously selected
    }
  };

  const handleCustomGoalChange = (event) => {
    const value = event.target.value;
    setCustomGoal(value);

    updateIsDisabled();
  };

  const optionalOtherInput = () => {
    return (
      <div className={classes.inputContainer}>
        <label htmlFor="customGoal" className={classes.inputLabel}>
          Enter your custom goal
          <span className={classes.requiredAsterisk}> *</span>
        </label>
        <input
          type="text"
          id="customGoal"
          placeholder="Enter your goal"
          className={`${classes.input} ${
            customGoal.trim() === "" ? classes.invalidInput : ""
          }`}
          value={customGoal}
          onChange={handleCustomGoalChange}
          maxLength={50}
          aria-describedby="goalHelp"
        />
        <small id="goalHelp" className={classes.inputHelp}>
          Please provide a custom goal.
        </small>
      </div>
    );
  };

  const optionalWebsiteInput = (labelText, required) => {
    return (
      <div className={classes.inputContainer}>
        <label htmlFor="website" className={classes.inputLabel}>
          {labelText}
          {required && <span className={classes.requiredAsterisk}> *</span>}
        </label>
        <input
          type="text"
          id="website"
          placeholder="Enter your website URL"
          className={`${classes.input} ${
            !isValid && required ? classes.invalidInput : ""
          }`}
          value={website}
          onChange={handleWebsiteChange}
          maxLength={50}
          aria-describedby="websiteHelp"
        />
        <small id="websiteHelp" className={classes.inputHelp}>
          {required
            ? "Please provide a valid URL."
            : "Provide your website URL (optional)"}
        </small>
      </div>
    );
  };

  const getVideoSource = () => {
    switch (orgLanguage) {
      case "es":
        return "/lw_ai_assistant_es.MOV";
      default:
        return "/lw_ai_assistant.MOV";
    }
  };

  const getVideoPoster = () => {
    switch (orgLanguage) {
      case "es":
        return "/thumbnail_onboarding_es.png";
      default:
        return "/thumbnail_onboarding.png";
    }
  };

  const getDiscoverText = () => {
    switch (orgLanguage) {
      case "es":
        return "Descubre cómo funciona LeadWire";
      default:
        return "Discover How LeadWire Works";
    }
  };

  return (
    <>
      {isLoading ? (
        isDelayed && (
          <div className="loading">
            <CircularProgress size={100} color="primary" />
          </div>
        )
      ) : (
        <div className={classes.wrapper}>
          <div className={classes.container}>
            <div className={classes.skipButtonContainer}>
              <Button
                className={`main-button px-3 py-2 ${classes["skip-button"]}`}
                color="light"
                onClick={handleSkip}
              >
                <FaBan /> <span>Skip</span>
              </Button>
            </div>
            <div className={classes["left-section"]}>
              <div className={classes.logo}>
                <Image
                  src={LWLogo}
                  alt="Freshchat Logo"
                  width={200}
                  height={200}
                  className={classes.image}
                />
              </div>
              <div className={classes.steps}>
                <div
                  className={`${classes.step} ${
                    currentStep === 1 ? classes.active : ""
                  }`}
                >
                  1
                </div>
                <div
                  className={`${classes.step} ${
                    currentStep === 2 ? classes.active : ""
                  }`}
                >
                  2
                </div>
              </div>
              <h1 className={classes.welcome}>
                {currentStep === 1 ? welcomeTitle : "Define Your Goals"}
              </h1>
              {currentStep === 1 && (
                <p className={classes["welcome-message"]}>
                  You've taken your first step to create remarkable connections
                  with your customers using text campaigns.
                </p>
              )}
              {currentStep === 2 && (
                <p className={classes["welcome-message"]}>
                  Now, let's define your goals to tailor your experience.
                </p>
              )}
              {currentStep === 1 && (
                <div className={classes["form-group"]}>
                  <h6 className={classes["form-title"]}>
                    Your Industry
                    <span className={classes.requiredAsterisk}> *</span>
                  </h6>

                  <Select
                    options={industryOptions}
                    value={selectedIndustry}
                    placeholder="Select your industry"
                    className={classes.select}
                    styles={optionStyle}
                    onChange={handleIsOptionSelected}
                  />
                  <small className={classes.inputHelp}>
                    Helps us personalize your experience
                  </small>
                </div>
              )}
              {currentStep === 2 && (
                <div className={classes["form-group"]}>
                  <h6 className={classes["form-title"]}>
                    What Are Your Goals with LeadWire?
                    <span className={classes.requiredAsterisk}> *</span>
                  </h6>

                  <Select
                    options={goalOptions}
                    value={selectedGoal}
                    placeholder="Select your goal"
                    className={classes.select}
                    styles={optionStyle}
                    onChange={handleGoalChange}
                  />
                  <small className={classes.inputHelp}>
                    Select a goal to help us tailor our services to your needs.
                  </small>
                  {selectedGoal && selectedGoal.value === "other"
                    ? optionalOtherInput()
                    : selectedGoal &&
                      (selectedGoal.value === "websiteVisits" ||
                        selectedGoal.value === "onlineSales")
                    ? optionalWebsiteInput("Enter your Website", true) // Required field
                    : selectedGoal && selectedGoal.value === "brandAwareness"
                    ? optionalWebsiteInput("Do you have a Website?", false) // Optional field
                    : null}
                </div>
              )}
              <div
                className={classes.buttons}
                style={{
                  justifyContent:
                    currentStep > 1 ? "space-between" : "flex-end",
                }}
              >
                {currentStep > 1 && (
                  <Button
                    className={`main-button px-3 py-2 ${classes["prev-button"]}`}
                    color="secondary"
                    outline
                    onClick={handlePrevious}
                  >
                    <BsArrowLeft /> <span>Back</span>
                  </Button>
                )}
                <Button
                  className={`main-button px-3 py-2 ${classes["next-button"]}`}
                  color="primary"
                  onClick={currentStep === 1 ? handleNext : handleSubmit}
                  disabled={isDisabled}
                  style={{ marginLeft: currentStep === 1 ? "auto" : "initial" }}
                >
                  <BsArrowRight />{" "}
                  <span>{currentStep === 1 ? "Next" : "Get Started"}</span>
                </Button>
              </div>
            </div>
            <div className={classes["right-section"]}>
              <video
                width="100%"
                controls
                poster={getVideoPoster()}
                className={classes.video}
              >
                <source src={getVideoSource()} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <a
                href="https://www.leadwireapp.com/learn/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className={classes["overview-text"]}>{getDiscoverText()}</p>
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default withRouter(DataEntry);
