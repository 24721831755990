import { AppNavbarBrand, AppSidebarToggler } from "@coreui/react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
} from "reactstrap";
import { FaBook, FaCogs, FaFileAlt } from "react-icons/fa";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import React, { Component } from "react";
import { compose, graphql } from "react-apollo";

import ArrowTooltip from "../../components/ArrowTooltip/ArrowTooltip";
import { Badge } from "@material-ui/core";
import DefaultHeaderDropdown from "./DefaultHeaderDropdown";
import { HiOutlineSwitchVertical } from "react-icons/hi";
import MessagesConsumption from "../../components/MessagesConsumption/MessagesConsumption";
import Notifications from "../../components/Notifications/Notifications";
import Organization from "../../queries/Organization";
import ParentToSubOrg from "../../queries/ParentToSubOrg";
import PropTypes from "prop-types";
import UpdateNotification from "../../mutations/updateNotification";
import UpdateUserOrg from "../../mutations/updateUserOrg";
import UserNotifications from "../../queries/UserNotifications";
import logo from "../../assets/img/brand/logo_tagline.png";
import { styled } from "@material-ui/core/styles";

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

const CustomBadge = styled(Badge)({
  "& .MuiBadge-badge": {
    backgroundColor: "var(--lw-blue-color)", // Change this to your desired color
    color: "white", // Change this to your desired text color
    transform: "scale(0.8) translate(50%, -30%)", // Change this to your desired size
  },
});
class DefaultHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNotifications: false,
      dropdownOpen: false,
      switchDropdownOpen: false,
      isHovered: false,
    };
  }

  componentDidMount() {
    // Add socket for notifications.refetch()
    const { socket } = this.props;
    if (!socket) return;

    socket.on("update-notifications", () => {
      this.props.notifications.refetch();
    });

    return () => {
      socket.off("update-notifications");
    };
  }

  toggleDropdown = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  toggleSwitchDropdown = () => {
    if (this.props.organizations.organizationToOrganization.length === 0) {
      return;
    }
    this.setState((prevState) => ({
      switchDropdownOpen: !prevState.switchDropdownOpen,
    }));
  };

  render() {
    const {
      data,
      notifications,
      updateNotification,
      organizations,
      organization,
    } = this.props;
    if (!localStorage.getItem("token")) {
      this.props.history.push("/login");
    }
    if (data.loading) {
      return <div></div>;
    }

    // Gets all the notifications from the db
    const notificationsData = notifications.notifications
      ? notifications.notifications
      : [];

    const handleShowNotifications = () => {
      setTimeout(() => {
        this.setState({ showNotifications: !this.state.showNotifications });
      }, 250);
      this.props.notifications.refetch();
    };

    const handleReadAll = () => {
      const notificationsId = notificationsData
        .filter((notification) => notification.isRead === false)
        .map((notification) => notification.id);

      notificationsId.forEach((id) => {
        const variables = {
          id: id,
          isRead: true,
        };

        updateNotification({ variables });
      });

      this.props.notifications.refetch();
    };

    const changeOrganization = async (organization) => {
      const { updateUserOrg } = this.props;

      const variables = {
        id: this.props.data.me.id,
        organizationId: organization.id,
        subAccountId: this.props.data.me.subAccount
          ? this.props.data.me.subAccount.id
          : null,
        changeOrganization: true,
      };
      await updateUserOrg({ variables });
      localStorage.setItem("organizationId", organization.id);
      localStorage.setItem("organization", organization.name);
      this.props.history.push("/dashboard");
      window.location.reload();
    };

    const handleNotificationReadChange = async (isReadButton) => {
      const selectedNotificationId = isReadButton.target.id;
      const selectedNotification = notificationsData.filter(
        (notification) => notification.id === selectedNotificationId
      );
      const selectedNotificationIsRead = selectedNotification[0].isRead;

      const variables = {
        id: selectedNotificationId,
        isRead: !selectedNotificationIsRead,
      };

      await updateNotification({ variables });
      this.props.notifications.refetch();
    };

    const organizationsData = organizations.organizationToOrganization
      ? organizations.organizationToOrganization
      : [];

    return (
      <React.Fragment>
        <AppSidebarToggler
          style={{ outline: "none" }}
          className="d-lg-none"
          display="md"
          mobile
        />
        <AppNavbarBrand
          className="logo-mobile"
          full={{ src: logo, width: 200, alt: "LeadWire Logo" }}
          minimized={{ src: logo, width: 30, alt: "LeadWire Logo" }}
          href="/dashboard"
        />
        <Nav className="header-nav-menu ml-auto" navbar>
          {/* Switch Org Dropdown */}
          {!this.props.data.loading &&
            this.props.data &&
            this.props.data.me &&
            this.props.data.me.isMain && (
              <ArrowTooltip
                className="info-tooltip"
                title={"Create a new Account to enable switching between them."}
                open={
                  this.state.isHovered &&
                  !this.props.organizations.loading &&
                  this.props.organizations.organizationToOrganization &&
                  this.props.organizations.organizationToOrganization.length ===
                    0
                }
              >
                <Dropdown
                  isOpen={this.state.switchDropdownOpen}
                  toggle={this.toggleSwitchDropdown}
                  onMouseEnter={() => this.setState({ isHovered: true })}
                  onMouseLeave={() => this.setState({ isHovered: false })}
                >
                  <DropdownToggle className="custom-dropdown-toggle">
                    {organization.organization
                      ? organization.organization.name
                      : "Switch Org"}{" "}
                    <HiOutlineSwitchVertical />
                  </DropdownToggle>
                  <DropdownMenu>
                    {organizationsData.length > 0 &&
                      organizationsData.map((organization, index) => {
                        if (index === 0) {
                          return (
                            <>
                              <DropdownItem
                                disabled={
                                  localStorage.getItem("organizationId") ===
                                  organization.parentOrganization.id
                                }
                                key={organization.parentOrganization.id}
                                onClick={() =>
                                  changeOrganization(
                                    organization.parentOrganization
                                  )
                                }
                              >
                                {localStorage.getItem("organizationId") ===
                                organization.parentOrganization.id ? (
                                  <MdCheckBox />
                                ) : (
                                  <MdCheckBoxOutlineBlank
                                    style={{ color: "grey" }}
                                  />
                                )}
                                <span style={{ marginLeft: "0.85rem" }}>
                                  {organization.parentOrganization.name +
                                    " (main)"}
                                </span>
                              </DropdownItem>
                              <DropdownItem
                                disabled={
                                  localStorage.getItem("organizationId") ===
                                  organization.subOrganization.id
                                }
                                key={organization.subOrganization.id}
                                onClick={() =>
                                  changeOrganization(
                                    organization.subOrganization
                                  )
                                }
                              >
                                {localStorage.getItem("organizationId") ===
                                organization.subOrganization.id ? (
                                  <MdCheckBox />
                                ) : (
                                  <MdCheckBoxOutlineBlank
                                    style={{ color: "grey" }}
                                  />
                                )}
                                <span style={{ marginLeft: "0.85rem" }}>
                                  {organization.subOrganization.name}
                                </span>
                              </DropdownItem>
                            </>
                          );
                        }
                        return (
                          <DropdownItem
                            disabled={
                              localStorage.getItem("organizationId") ===
                              organization.subOrganization.id
                            }
                            key={organization.subOrganization.id}
                            onClick={() =>
                              changeOrganization(organization.subOrganization)
                            }
                          >
                            {localStorage.getItem("organizationId") ===
                            organization.subOrganization.id ? (
                              <MdCheckBox />
                            ) : (
                              <MdCheckBoxOutlineBlank
                                style={{ color: "grey" }}
                              />
                            )}{" "}
                            <span style={{ marginLeft: "0.85rem" }}>
                              {organization.subOrganization.name}
                            </span>
                          </DropdownItem>
                        );
                      })}
                  </DropdownMenu>
                </Dropdown>
              </ArrowTooltip>
            )}
          {/* Developers Dropdown */}
          <CustomBadge badgeContent="New">
            <Dropdown
              isOpen={this.state.dropdownOpen}
              toggle={this.toggleDropdown}
            >
              <DropdownToggle className="custom-dropdown-toggle">
                Developers
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    this.props.history.push("/api-logs");
                  }}
                >
                  <FaFileAlt style={{ color: "grey" }} />
                  <span style={{ marginLeft: "0.85rem" }}>API Logs</span>
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    this.props.history.push("/integrations");
                  }}
                >
                  <FaCogs style={{ color: "grey" }} />
                  <span style={{ marginLeft: "0.85rem" }}>Integrations</span>
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    process.env.REACT_APP_LEADWIRE_API.includes("dev")
                      ? window.open(
                          "https://leadwire-api-dev.herokuapp.com",
                          "_blank"
                        )
                      : window.open(
                          "https://developers.leadwireapp.io",
                          "_blank"
                        );
                  }}
                >
                  <FaBook style={{ color: "grey" }} />
                  <span style={{ marginLeft: "0.85rem" }}>Docs</span>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </CustomBadge>
          <MessagesConsumption
            withStyle
            organization={this.props.organization}
          />
          <DefaultHeaderDropdown help history={this.props.history} />
          <Notifications
            showNotification={this.state.showNotifications}
            handleShowNotifications={handleShowNotifications}
            handleReadAll={handleReadAll}
            notifications={notificationsData}
            handleNotificationReadChange={handleNotificationReadChange}
            onClickOutside={() => {
              this.setState({ showNotifications: false });
            }}
          />
          <DefaultHeaderDropdown accnt history={this.props.history} />
        </Nav>
      </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default compose(
  graphql(UserNotifications, {
    options: (props) => ({
      variables: { id: props.data.me ? props.data.me.id : "" },
    }),
    name: "notifications",
  }),
  graphql(UpdateNotification, { name: "updateNotification" }),
  graphql(Organization, {
    options: (props) => ({
      variables: {
        id: localStorage.getItem("organizationId"),
      },
    }),
    name: "organization",
  }),
  graphql(ParentToSubOrg, {
    options: (props) => ({
      variables: {
        id:
          props.organization.organization &&
          props.organization.organization.parentOrg.length > 0 &&
          !props.organization.loading
            ? props.organization.organization.parentOrg[0].id
            : localStorage.getItem("organizationId"),
      },
    }),
    name: "organizations",
  }),
  graphql(UpdateUserOrg, { name: "updateUserOrg" })
)(DefaultHeader);
