import React, { Component } from "react";
import { graphql, compose } from "react-apollo";
import OrganizationWithBillingInfo from "../../queries/OrganizationWithBillingInfo";
import sharedBillingInAccounts from "../../queries/sharedBilling";
import { Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

import ArrowTooltip from "../ArrowTooltip/ArrowTooltip";
import CanvasJSReact from "../../components/CanvasJS/canvasjs.react";
import moment from "moment/moment";

const divStyle = {
  padding: "0",
};

const pStyle = {
  display: "inline",
  margin: "0",
  marginTop: "1.5px",
};

class MessagesConsumption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialLoad: true, // Track whether the initial load is happening
    };
  }

  componentDidMount() {
    // Start polling every 5 seconds
    this.refetchInterval = setInterval(() => {
      if (
        this.props.organizationUsage &&
        this.props.organizationUsage.refetch
      ) {
        this.props.organizationUsage.refetch();
      }
    }, 5000);
  }

  componentDidUpdate(prevProps) {
    if (
      this.state.initialLoad &&
      !this.props.organizationUsage.loading &&
      this.props.organizationUsage.organizationUsage
    ) {
      // Once the first fetch completes, disable loading state
      this.setState({ initialLoad: false });
    }
  }

  componentWillUnmount() {
    // Clear the interval to avoid memory leaks
    if (this.refetchInterval) {
      clearInterval(this.refetchInterval);
    }
  }

  renderUsageChart(totalMessages, planAmount) {
    const messagesLeft =
      totalMessages <= planAmount ? planAmount - totalMessages : 0;
    const colorChanger = totalMessages > planAmount ? "#ff0000" : "#4DBE74";
    const CanvasJSChart = CanvasJSReact.CanvasJSChart;

    const options = {
      animationEnabled: false,
      width: 30,
      height: 30,
      interactivityEnabled: false,
      axisY: {
        gridThickness: 0,
      },
      theme: "light2",
      data: [
        {
          type: "doughnut",
          showInLegend: false,
          dataPoints: [
            { y: totalMessages, color: colorChanger }, // Amount used
            { y: messagesLeft, color: "#939ea9" }, // Total of messages left
          ],
        },
      ],
    };

    return (
      <div style={{ marginLeft: "5px", marginRight: "20px" }}>
        <CanvasJSChart options={options} />
      </div>
    );
  }

  render() {
    const { history, sharedBillingInAccounts } = this.props; // Props from queries
    if (!localStorage.getItem("token")) {
      history.push("/login");
    }

    if (
      (this.state.initialLoad && this.props.organizationUsage.loading) ||
      (sharedBillingInAccounts && sharedBillingInAccounts.loading)
    ) {
      return localStorage.getItem("role") !== "COUPONS" ? (
        <p className="align-center mb-0">
          <Skeleton variant="text" animation="wave" width={200} />
        </p>
      ) : null;
    }

    const { organizationUsage } = this.props.organizationUsage;
    let sharedBilling = false;

    if (!(organizationUsage && organizationUsage.plan)) {
      return (
        <div style={this.props.withStyle ? divStyle : {}}>
          <p
            className="align-center"
            style={this.props.withStyle ? pStyle : {}}
          >
            {this.props.withStyle ? (
              <>
                Plan Usage:<b> No plan selected</b>
              </>
            ) : (
              <>
                <b>Plan Usage:</b> No plan selected
              </>
            )}
          </p>
        </div>
      );
    }

    if (sharedBillingInAccounts && !sharedBillingInAccounts.loading) {
      sharedBilling = sharedBillingInAccounts.sharedBillingRecords;
    }

    const planAmount =
      organizationUsage.plan.amountMessages && !sharedBilling
        ? organizationUsage.plan.amountMessages
        : "-";

    let planUsage = 0;
    let totalOnAccount = 0;
    let totalMessages = 0;

    if (organizationUsage.UsageCycle && organizationUsage.UsageCycle[0]) {
      const { outboundCount, inboxCount, autoReplyCount, cycle } =
        organizationUsage.UsageCycle[0];

      totalMessages =
        this.props.organizationUsage.organizationUsage.UsageCycle[0]
          .inboxCount +
        this.props.organizationUsage.organizationUsage.UsageCycle[0]
          .autoReplyCount +
        this.props.organizationUsage.organizationUsage.UsageCycle[0]
          .outboundCount;
      planUsage = this.props.onAccount ? (
        `\tCampaigns: ${outboundCount.toLocaleString()}\n\tAuto-reply: ${autoReplyCount.toLocaleString()}\n\tInbox: ${inboxCount.toLocaleString()}`
      ) : (
        <div>
          <div>
            <b>
              Usage since: {moment.utc(new Date(cycle)).format("MMMM DD, YYYY")}
            </b>
          </div>
          <div>Campaigns: {outboundCount.toLocaleString()}</div>
          <div>Auto-reply: {autoReplyCount.toLocaleString()}</div>
          <div>Inbox: {inboxCount.toLocaleString()}</div>
          <div>
            <b>Total credits: {totalMessages.toLocaleString()}</b>
          </div>
        </div>
      );
      totalOnAccount = `\tTotal usage: ${totalMessages.toLocaleString()}`;
    }

    if (this.props.withTypography) {
      const date = new Date(organizationUsage.UsageCycle[0].cycle);
      // Date.getMonth() seems to always add +31 days, this may cause the date to be off
      //date.setMonth(date.getMonth() + 1);
      const dateString = moment.utc(date).add(1, "M").format("MMMM DD, YYYY");
      return (
        <div style={{ whiteSpace: "pre-wrap" }}>
          <div className="d-flex justify-content-start">
            <Typography className="text-muted w-50" variant="overline">
              Plan Usage
            </Typography>
            <p style={{ marginTop: "4px" }}>Renews on: {dateString}</p>
          </div>
          {this.props.onAccount && (
            <p style={{ margin: "0px" }}>
              <b>{totalOnAccount}</b>
            </p>
          )}
          <p>{planUsage}</p>
        </div>
      );
    }

    return (
      <div style={this.props.withStyle ? divStyle : {}}>
        {localStorage.getItem("role") !== "COUPONS" ? (
          <ArrowTooltip className="info-tooltip" title={planUsage}>
            <div style={{ display: "inline-flex", alignItems: "center" }}>
              <p
                className="align-center"
                style={this.props.withStyle ? pStyle : {}}
              >
                {this.props.withStyle ? (
                  <>
                    Plan Usage:
                    <b> {totalMessages.toLocaleString()}</b>
                    {planAmount === "-" ? (
                      ""
                    ) : (
                      <b> / {planAmount.toLocaleString()}</b>
                    )}
                  </>
                ) : (
                  <>
                    <b>Plan Usage:</b> {totalMessages.toLocaleString()} /{" "}
                    {planAmount.toLocaleString()}
                  </>
                )}
              </p>
              {this.renderUsageChart(totalMessages, planAmount)}
            </div>
          </ArrowTooltip>
        ) : null}
      </div>
    );
  }
}

export default compose(
  graphql(sharedBillingInAccounts, {
    name: "sharedBillingInAccounts",
    skip: (props) => {
      if (!props.organization) return true;
    },
    options: () => {
      return {
        variables: { currentOrgId: localStorage.getItem("organizationId") },
      };
    },
  }),
  graphql(OrganizationWithBillingInfo, {
    options: () => ({
      variables: {
        id: localStorage.getItem("organizationId"),
        fetchPolicy: "network-only",
      },
    }),
    name: "organizationUsage",
  })
)(MessagesConsumption);
